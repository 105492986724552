<template>
  <div>
    <BaseTableHeader
      appSelect
      @appChange="appChange"
      @searchChange="searchChange"
      @refresh="refresh"
    />

    <BaseTable
      :headers="headers"
      :items="appTemplates"
      :search="search"
      @rowClick="rowClick"
      rowKey="name"
    />
  </div>
</template>

<script>
import { headers } from '@/components/config/tenantAppTemplates';
import baseTableMixin from '@/components/mixins/workspace/baseTableMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'TenantTemplateTable',
  mixins: [baseTableMixin],
  props: {
    templates: Array,
  },
  data: () => ({
    headers,
  }),
  methods: {
    appChange(item) {
      this.$emit('appChange', item);
    },
  },
  computed: {
    ...mapGetters('applicationTemplates', {
      appTemplates: 'getAll',
    }),
    ...mapGetters('app', ['getSelectedTemplateApp']),
  },
};
</script>
